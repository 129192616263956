<template>
    <power-bi code="eyJrIjoiOTI2Mjg1OTUtNzMzZC00N2ExLTgxMjUtNDU0NzEzMzJmNDc0IiwidCI6ImM4NDJjMWQ5LWQzNjQtNDcxNi1iN2UzLWNhNDgxYTIzZDZhYyJ9"></power-bi>
</template>

<script>
import PowerBi from '../components/PowerBi.vue'

export default {
    components: {
        PowerBi
    }
}
</script>
